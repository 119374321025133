<script setup lang="ts">
interface ISettingsBaseContainer {
  title?: string;
  titleClass?: string;
  miniContainer?: boolean;
}
const props = withDefaults(defineProps<ISettingsBaseContainer>(), {
  title: '',
  miniContainer: false,
});
</script>
<template>
  <div
    class="text-base-priority"
    :class="
      props.miniContainer
        ? 'sm:rounded-lg p-4 pt-3 w-full sm:max-w-[428px] sm:mx-auto bg-layer-1 mb-4'
        : 'rounded-lg sm:py-1 mx-auto'
    "
  >
    <div
      v-if="title"
      class="block font-bold"
      :class="
        props?.titleClass + (props.miniContainer ? ' mb-3' : ' my-2 md:hidden')
      "
    >
      {{ title }}
    </div>
    <slot />
  </div>
</template>
